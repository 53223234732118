<template>
    <div>
        <div class="row" style="margin-bottom:20px">
            <div class="col-md-12" style="padding:14px">
                <div class="col-md-12" style="padding:0">
                    <div class="card shadow " style="margin-top:20px;" v-show="this.$root.isMobile == false">
                        <div class="card-header card-header-background">
                            <h2><i class="fa-fw fal fa-store-alt"></i> Società</h2>
                        </div>
                        <div class="card-body" style="padding:0">
                            <div class="row" style="padding:10px">
                                <div style="float:left;margin-left:20px">
                                    <img :src="'https://assosprint.it/app/IMG/LOGHI/'+ negozio.URL_LOGO" style="max-height:140px;" class="img-responsive shadow" alt="logo negozio">
                                </div>
                                <div style="float:left;margin-left:20px">
                                    <div style="margin-top:0px" v-if="negozio.ID_NEGOZIO > 0">
                                        <div class="font-weight-bold  mb-1" style="font-size:24px">
                                            {{negozio.ALIAS}}
                                        </div>  
                                        <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                            <i class="fal fa-map-marker-alt fa-fw"></i> {{negozio.INDIRIZZO}} - {{negozio.comune.NOME}}
                                        </div> 
                                        <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                            <i class="fal fa-phone fa-fw"></i>{{negozio.TELEFONO}}
                                        </div> 
                                        <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                            <i class="fal fa-credit-card fa-fw"></i>{{negozio.ACCETTA_PAGAMENTI_ONLINE == 1 ? 'Accetta' : 'NON accetta'}} pagamenti online
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card shadow " style="margin-top:6px;" v-show="this.$root.isMobile == true">
                        <div class="card-header card-header-background">
                            <h2><i class="fa-fw fal fa-store-alt"></i> Società</h2>
                        </div>
                        <div class="card-body" style="padding:0">
                            <div class="row" style="padding:10px">
                                <img :src="'https://assosprint.it/app/IMG/LOGHI/'+ negozio.URL_LOGO" style="max-height:100px;margin:0 auto" class="img-responsive shadow" alt="logo negozio">
                                <div style="margin-top:0px;text-align:center;width:100%;" v-if="negozio.ID_NEGOZIO > 0">
                                    <div class="font-weight-bold  mb-1" style="font-size:24px">
                                        {{negozio.ALIAS}}
                                    </div>  
                                    <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                        <i class="fal fa-map-marker-alt fa-fw"></i> {{negozio.INDIRIZZO}} - {{negozio.comune.NOME}}
                                    </div> 
                                    <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                        <i class="fal fa-phone fa-fw"></i>{{negozio.TELEFONO}}
                                    </div> 
                                    <div class="font-weight-bold mb-1" style="font-size:16px;color:#6c6c6c">
                                        <i class="fal fa-credit-card fa-fw"></i>{{negozio.ACCETTA_PAGAMENTI_ONLINE == 1 ? 'Accetta' : 'NON accetta'}} pagamenti online
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-md-7">
                        <div class="card shadow " style="margin-top:10px;">
                            <div class="card-header card-header-background">
                                <h2><i  class="fa-fw fal fa-calendar-alt  "></i>Attività prenotabili
                                </h2>
                            </div>
                            <div class="card-body" style="padding:0">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="attivita in arrayAttivita" v-bind:key="attivita.ID_ATTIVITA">
                                        <div class="col-8" style="padding-left:0px">
                                            <div style="float:left;font-size:36px;margin-right:30px">
                                                {{filters.formatDateNoYear(attivita.DATA) }}
                                            </div>
                                            <div style="float:left">
                                                <strong>{{attivita.DESCRIZIONE}}<small> - {{attivita.TOT}} / {{attivita.MAX_SOCI}} prenotati</small></strong><br/><span style="font-size:16px"><small>{{filters.formatTime(attivita.ORA_INIZIO)}} - {{filters.formatTime(attivita.ORA_FINE)}}</small></span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <button v-on:click="prenotaAttivita(attivita)" class="btn btn-primary float-right" ><i class="fal fa-check"></i> Prenota</button>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div>   
                    <div class="col-md-5">
                         <div class="card shadow " style="margin-top:10px;">
                            <div class="card-header card-header-background" style="background:#69b034 !important">
                                <h2><i  class="fa-fw fal fa-check  "></i>Attività prenotate
                                </h2>
                            </div>
                            <div class="card-body" style="padding:0">
                                 <ul class="list-group">
                                    <li class="list-group-item" style="cursor:pointer;" v-for="attivita in arrayPrenotazioni" v-bind:key="attivita.ID_PRENOTAZIONE" >
                                        <div class="col-8">
                                             <div style="float:left;font-size:36px;margin-right:30px">
                                                {{filters.formatDateNoYear(attivita.DATA) }}
                                            </div>
                                            <div style="float:left">
                                                <strong>{{attivita.DESCRIZIONE}}<small></small></strong><br/><span style="font-size:16px"><small>{{filters.formatTime(attivita.ORA_INIZIO)}} - {{filters.formatTime(attivita.ORA_FINE)}}</small></span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <button v-on:click="annullaPrenotazione(attivita)" class="btn btn-danger float-right" ><i class="fal fa-trash-alt"></i> Annulla</button>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div>             
                </div>
            </div>
        </div>
    </div>
</template>
<script>



export default {
    name:"Prenotazioni",
    data:function(){
        return{
            filters:global.filters,
            arrayProdotti : new Array(),
            negozio : {},
            arraySoci : new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:200},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            prodottoSelezionato:{},
            socioSelezionato : {},
            prodottoAsSelezionato : {},
            arrayMesi : new Array(),
            arrayMesiSelezionati : new Array(),
            arrayRateScadenzario : new Array(),
            rataScadenzarioSelezionata : {},
            arrayAttivita : new Array(),
            arrayPrenotazioni : new Array()
        }
    },
    methods: {
        
        getImmagineSfondo : function(prodotto){
            var url = 'CORE/UPLOAD/PRODOTTI/'+ prodotto.URL_IMMAGINE;
            return {
                "background-image": "url('"+ url +"')",
                "background-position":"center",
                "background-repeat":"no-repeat",
                "background-size":"cover",    
            }
        },
        prenotaAttivita : function(attivita){
            utils.alert.confirm("Sei sicuro di voler prenotare l'attività <strong>"+attivita.DESCRIZIONE + "<br/>DATA: "+ this.filters.formatDate(attivita.DATA) +" - ORARIO: " + this.filters.formatTime(attivita.ORA_INIZIO)+ " - " + this.filters.formatTime(attivita.ORA_FINE) + "</strong><BR/>per il socio <strong>" + attivita.NOME + " " + attivita.COGNOME + "</strong>?",() => {
                utils.ajax('deskalo/attivita/prenota',{ID_CORSO : attivita.ID_CORSO,ID_SOCIO:attivita.ID_SOCIO,ID_DATA : attivita.ID_DATA,ORA_INIZIO : attivita.ORA_INIZIO,ORA_FINE:attivita.ORA_FINE}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax('/deskalo/prenotazioni/startup',{}, (response) => {
                            this.arrayAttivita = response.data.ATTIVITA;
                            this.arrayPrenotazioni = response.data.PRENOTAZIONI_ATTIVE;
                        });
                    }
                });
            },this.$root);
        },
        mostraPrenotazioni : function(){
            var modal = $('#popUpPrenotazioni');
            modal.modal('show');
        },
        annullaPrenotazione : function(attivita){
            var modal = $('#popUpPrenotazioni');
            modal.modal('hide');
            utils.alert.confirm("Sei sicuro di voler annullare la prenotazione dell'attività <strong>"+attivita.DESCRIZIONE + "<br/>DATA: "+ this.filters.formatDate(attivita.DATA) +" - ORARIO: " + this.filters.formatTime(attivita.ORA_INIZIO)+ " - " + this.filters.formatTime(attivita.ORA_FINE) + "</strong><BR/>per il socio <strong>" + this.$root.getSocio(this.$root.DESKALO_SOCIO_SELEZIONATO.ID_SOCIO,this.$root.DESKALO_SOCIO_SELEZIONATO.ID_NEGOZIO).NOME + " " + this.$root.getSocio(this.$root.DESKALO_SOCIO_SELEZIONATO.ID_SOCIO,this.$root.DESKALO_SOCIO_SELEZIONATO.ID_NEGOZIO).COGNOME + "</strong>?",() => {
                utils.ajax('/deskalo/attivita/annulla',{ID_PRENOTAZIONE:attivita.ID_PRENOTAZIONE}, (response) => {
                    if (response.esito == 0){
                        this.arrayAttivita = response.data.ATTIVITA;
                        this.arrayPrenotazioni = response.data.PRENOTAZIONI_ATTIVE;
                        utils.alert.success(response.messaggio);
                    }
                });
            },this.$root);
        },
    },
    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayProdotti,this.search);
            return arr;
        },
    },
    created : function() {
        utils.ajax('/deskalo/prenotazioni/startup',{}, (response) => {
            this.negozio = response.data.NEGOZIO;
            this.arrayAttivita = response.data.ATTIVITA;
            this.arrayPrenotazioni = response.data.PRENOTAZIONI_ATTIVE; 
        });
    },
}
</script>