<template>
    <div class="modal fade" id="modalRegistrazione" tabindex="-1" role="dialog"  aria-modal="true" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-info">
                    <h5 class="modal-title" id="exampleModalLabel">Registrazione utente</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="text-align:center;margin-bottom:6px">
                        <img class="img-responsive" style="max-height:100px;margin:0 auto" src="~@/assets/img/deskalo.png">
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Nome</label>
                            <input type="text" class="form-control" placeholder="Nome" v-model="utente.NOME">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Cognome</label>
                            <input type="text" class="form-control" placeholder="Cognome" v-model="utente.COGNOME">
                        </div>                    
                    </div>
                    <div class="row">
                        <!--
                        <div class="form-group col-md-6">
                            <label>Username</label>
                            <input type="text" class="form-control" placeholder="Username" v-model="utente.USERNAME">
                        </div>-->
                        <div class="form-group col-md-12">
                            <label>Email</label>
                            <input type="text" class="form-control" placeholder="Email" v-model="utente.EMAIL">
                        </div>                    
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password" v-model="utente.PASSWORD">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Conferma Password</label>
                            <input type="password" class="form-control" placeholder="Conferma password" v-model="utente.CONFERMA_PASSWORD">
                        </div>                    
                    </div>
                    <div class="form-text" style="font-size:12px;margin-top:-5px">
                        <div>La tua password deve avere una lunghezza minima di 8 caratteri.<br/>Deve contenere almeno un numero ed una lettera maiuscola</div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-1">
                            <input type="checkbox" true-value="1" false-value="0" style="width:24px;height:24px" v-model="terminiecond" >                
                        </div>
                        <div class="col-11">
                            <span>Accetto i <a :href="link" target="_blank"><strong>termini e le condizioni del servizio</strong></a></span>                
                        </div>                    
                    </div>
                </div>
                <div class="modal-footer">
                    <button :disabled="terminiecond == 0" type="button" class="btn btn-success btn-lg btn-block" v-on:click="registrazione()"><i class="fa fa-check fa-fw"></i>Registrati</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Registrazione',
    props:['api','logo','link'],
    data:function(){
        return{
            utente:{},
            terminiecond:0
        }
    },
    methods: {
        registrazione : function(){
            var api = this.api != undefined && this.api != null ? this.api : 'core/registrazione';
            this.utente.USERNAME = this.utente.EMAIL;
            utils.ajax(api,this.utente, (response) => {
                if (response.esito == 0){
                    utils.alert.standard(this.$root,response.messaggio,null,"Attenzione","Ok","fal fa-check");
                    var modal = $('#modalRegistrazione');
                    modal.modal('hide');
                }
                else{
                    utils.alert.standard(this.$root,response.messaggio,null,"Attenzione","Ok","fal fa-exclamation-triangle")
                }
            },true,1);
        }
    },
}
</script>