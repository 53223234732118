<template>
    <div class="row">
        <div class="col-md-4 offset-md-4">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                    <div class="row">
                        <div class="col-12" style="padding:0">
                            <div class="container">
                                <div class="p-5">
                                    <div class="text-center" style="margin-bottom:20px;width:100%">
                                        <img  src="~@/assets/img/deskalo.png" style="width:100%" class="img-responsive d-none d-sm-block">
                                       <span class="h4">Deskalo</span>
                                    </div>
                                    <div class="user">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" class="form-control form-control-user"  v-model="utente.USERNAME"  >
                                        </div>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control form-control-user"  v-on:keyup.enter="login()"  v-model="utente.PASSWORD" >
                                        </div>
                                        <div class="form-group">
                                            <div>
                                                <a class="small" href="#" v-on:click="passwordDimenticata()">Password dimentica?</a>
                                            </div>
                                        </div>
                                        <button v-on:click="login()" class="btn btn-success btn-lg btn-user btn-block"><i class="fal fa-sign-in-alt fa-fw"></i> Login</button>
                                        
                                        <hr>
                                        <button v-on:click="registrazione()" class="btn btn-warning btn-user btn-lg btn-block"><i class="fal fa-pencil-alt fa-fw"></i> Registrati</button>
                                        
                                    </div>
                                </div>          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="modal fade" id="popUpEmail"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog" style="max-width:574px"  >
                <div class="modal-content" >
                    <div class="modal-header modal-header-info">
                        <h5 class="modal-title">Password dimenticata</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <label>Indirizzo email</label>
                            <input type="text"  class="form-control" v-model="emailPasswordDimenticata">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" v-on:click="doModificaPassword()"><i class="fa fa-check fa-fw"></i> Invia</button>
                    </div>
                </div>
            </div>
        </div>   
        <Registrazione :logo="'assets/img/deskalo.png'" :api="'deskalo/registrazione'"></Registrazione>
    </div>


</template>

<script>

import Registrazione from '@/components/common/Registrazione.vue'


export default {
    name: 'Login',
    components:{
        Registrazione
    },
    data:function(){
        return {
            emailPasswordDimenticata:'',
            utente:{
                //email:'utente@utente.it',
                //password:')7.HRK=L3M!xiyRq,vZ'
            }
        }
    },
    methods : {
        login(){
            global.utils.ajax('core/login',{USERNAME:this.utente.USERNAME,PASSWORD:this.utente.PASSWORD}, (response) => {
                if (response.esito == 0){
                    global.utils.setCookie("session_token",response.data.SESSION_TOKEN);
                    global.utils.TOKEN = response.token;
                    this.$root.startHandler();
                }
                else{
                    global.utils.alert.warning(response.responseText);
                }
            });
        },
        passwordDimenticata : function(){
            var modal = $('#popUpEmail');
            modal.modal('show');
        },
        doModificaPassword : function(){
            utils.ajax('as/utente/password/reset',{EMAIL:this.emailPasswordDimenticata},function(data){
            if (data.esito == 0){
                utils.alert.success(response.messaggio);
            }
            });
        },
        registrazione(){
            var modal = $('#modalRegistrazione');
            modal.modal('show');
        }
    },
    created : function(){
        global.utils.setHeader('fa-sign-in','Login');
    }
}
</script>