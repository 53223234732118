<template>
    <h2>Elenco ricevute per il socio<br/><small>Fai click per stampare il pdf</small></h2>
    <ul class="list-group">
        <li class="list-group-item" v-for="pagamento in socio.pagamenti" v-bind:key="pagamento.ID_PAGAMENTO" v-on:click="esportaPagamento(pagamento)">
            <div class="col-8">
                <span class="badge badge-m badge-success" style="font-size:16px"  >{{pagamento.NUMERO_RICEVUTA_FORMATTATO}}</span> 
                <span class="badge badge-m badge-success ml-2" style="font-size:16px"  >{{filters.formatDate(pagamento.DATA_PAGAMENTO)}}</span>                
            </div>
            <div class="col-4" style=" text-align:right">
                <strong style="font-size:24px">€ {{filters.formattaImporto(pagamento.IMPORTO)}}</strong>
            </div>
            <!--
            <div  class="col-12">
                <div v-for="quota in pagamento.QUOTE" v-bind:key="quota.ID_QUOTA" class="as-badge-grey" style="margin-top:2px;margin-bottom:2px">
                    <span class="badge  badge-m" >{{quota.DESCRIZIONE}}</span>
                    <span class="badge badge-m float-right mr-1" v-bind:class="{'badge-success':pagamento.FLG_VALIDO == 1 , 'badge-danger' : pagamento.FLG_VALIDO == 0}"  >{{filters.formattaImporto(quota.IMPORTO)}}</span><br/>
                </div>
            </div>-->
        </li>
    </ul>
</template>
<script>
export default {
    name:"Ricevute",
    data:function(){
        return{
            filters:global.filters,
            socio:{}
        }
    },
    methods: {  
        esportaPagamento : function(pagamento){
            window.open(utils.BASE_API + "core/report?ID_REPORT=504&PAGAMENTI="+pagamento.ID_PAGAMENTO);
        },
    },
    computed: {

    },
    created : function() {
        utils.ajax('/deskalo/socio',{}, (response) => {
            this.socio = response.data;
        });
    },
}
</script>